import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const Index = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 20 },
    { field: "nombre", headerName: "Nombre", width: 170 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "telefono", headerName: "Teléfono", width: 150 },
    { field: "tipo", headerName: "Tipo", width: 170 },
    { field: "mensaje", headerName: "Mensaje", width: 500 },
  ];

  const [value, setValue] = React.useState(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const getAuth = async () => {
      const response = await axios.get(
        "https://kernel.alcuboarquitectura.com/api/dashboardfb"
      );
      setTableData(response.data);
    };
    getAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <main>
      <Helmet>
        <title>Facebook</title>
      </Helmet>
      <Box>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Facebook"
            icon={<FacebookIcon />}
            component={Link}
            to="/"
          />
          <BottomNavigationAction
            label="Google Ads"
            icon={<GoogleIcon />}
            component={Link}
            to="/google"
          />

          {/* <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} /> */}
        </BottomNavigation>
      </Box>
      <div style={{ height: 700, width: "100%" }}>
        <DataGrid
          rows={tableData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[12]}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </main>
  );
};

export default Index;
